import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { graphql } from "gatsby";
export const query = graphql`
query ($myComponentPath: String!) {
    mdx(fileAbsolutePath: {eq: $myComponentPath}) {
        frontmatter {
            image {
                publicURL
            }
            imageSmall {
                publicURL
            }
        }
    }
}
`;
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`We're sorry, the page you're looking for was lost in a space. Please try using site search.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Not found",
  "date": "2015-02-04T20:27:42.000Z",
  "image": "./image.jpg",
  "imageSmall": "./imageSmall.jpg",
  "overview": "We're sorry, the page was lost in space."
};
    